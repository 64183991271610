import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

import Box from "@mui/material/Box";
import SearchInput from "./SearchInput";
//import Data from "./mock-data.json"
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditDialog from "./EditDialog";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { baseUrl } from "../../_constants";
import { Navigate } from "react-router-dom";
import { getAuthToken } from "../../utils";

import "./style.css";

const DF = new Intl.DateTimeFormat("en-US", { timeZoneName: "short" });

const classes = {
  root: {
    flexGrow: 1,
    fontFamily:"Lato",
  },
  id: {
    textAlign: "left",
    backgroundColor: "yellow",
    color: "black",
  },
  paper: {
    paddingRight: "0px",
    paddingLeft: "10px",
    paddingTop: "14px",
    paddingBottom: "14px",
    textAlign: "left",
    backgroundColor: "white",
    color: "black",
    fontFamily:"Lato",
  },
  main: {
    backgroundColor: "green",
    fontFamily:"Lato",
  },
  edit: {
    paddingRight: "0px",
    paddingLeft: "10px",
    paddingTop: "14px",
    paddingBottom: "14px",
    backgroundColor: "white",
    fill: "darkgreen",
    cursor: "pointer",
    fontFamily:"Lato",
  },
  editIcon: {
    fill: "blue",
  },
  delete: {
    paddingRight: "0px",
    paddingLeft: "10px",
    paddingTop: "14px",
    paddingBottom: "14px",
    backgroundColor: "white",
    fill: "darkgreen",
    cursor: "pointer",
    fontFamily:"Lato",
  },
  deleteIcon: {
    fill: "red",
  },
  addIcon: {
    fill: "blue",
  },
  header: {
    backgroundColor: "white",
    color: "black",
    fontSize: "20px",
    display: "table",
    cursor: "pointer",
    fontFamily:"Lato",
  },
};
const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "blue",
  border: "1",
}));

const RowItem = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  border: "1",
  color: "blue",
}));

/**
 * Returns a string formatted date for display,
 * like 01/02/22 @ 2:37 PM PT. Expects a valid JavaScript Date.
 *
 * TODO FIXME. This is hacky code, and probably should be replaced with a library.
 */
const formatDate = (lastSignInTime) => {
  if (!lastSignInTime) return "";
  const d = new Date(lastSignInTime);
  const month = d.getMonth() + 1; // convert to 1-based month, so 1 to 12
  const mm = month < 10 ? "0" + month : month;
  const dd = d.getDate();
  const year = d.getFullYear();
  const yy = year;
  const hours = d.getHours();
  const hh = hours < 13 ? hours : hours - 12;
  const ampm = hours < 12 ? "AM" : "PM";
  const parts = DF.formatToParts(d);
  const tz =
    parts.length == 7 && parts[6].type == "timeZoneName" ? parts[6].value : "";
  const minutes = d.getMinutes();
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const show =
    mm +
    "/" +
    dd +
    "/" +
    yy +
    " @ " +
    hh +
    ":" +
    formattedMinutes +
    " " +
    ampm +
    " " +
    tz;
  // TODO FIXME. Make sure this date is correct and add the time zone.
  return show;
};

const getAllData = (userData, handleClickOpenEdit, handleClickDelete) => {
  if (!userData) return <Box sx={{ flexGrow: 1 }} style={classes.main}></Box>;
  return userData.map((userInfo) => {
    return (
      <Box sx={{ flexGrow: 1 }} style={classes.main} key={userInfo.id}>
        <RowItem container spacing={0} alignItems="center">
          {/*
                <Grid item xs={1}>
                    <Item style={classes.id}>{userInfo.id}</Item>
                </Grid>
                */}
          <Grid item xs={2}>
            <Item style={classes.paper}>{userInfo.username}</Item>
          </Grid>
          <Grid item xs={3}>
            <Item style={classes.paper}>{userInfo.email}</Item>
          </Grid>
          <Grid item xs={2}>
            <Item style={classes.paper}>{userInfo.role}</Item>
          </Grid>
          <Grid item xs={3}>
            <Item style={classes.paper}>
              {formatDate(userInfo.lastSignInTime)}
            </Item>
          </Grid>
          <Grid
            item
            xs={1}
            style={classes.edit}
            onClick={() => {
              handleClickOpenEdit(userInfo);
            }}
          >
            <EditIcon style={classes.editIcon} />
          </Grid>
          <Grid
            item
            xs={1}
            style={classes.delete}
            onClick={() => {
              handleClickDelete(userInfo);
            }}
          >
            <DeleteForeverIcon style={classes.deleteIcon} />
          </Grid>
        </RowItem>
      </Box>
    );
  });
};

const getSomeData = (
  userQuery,
  userData,
  handleClickOpenEdit,
  handleClickDelete
) => {
  const lower = userQuery.toLowerCase();
  const searchResults = userData.filter((userInfo, i) => {
    const userName = userInfo.username;
    if (userName) {
      const nameParts = userName.split(/[ ]+/);
      return nameParts.some((part) => part.toLowerCase().startsWith(lower));
    } else {
      return false;
    }
  });
  return getAllData(searchResults, handleClickOpenEdit, handleClickDelete);
};

const getHeaders = () => {
  return (
    <Box sx={{ flexGrow: 1 }} style={classes.main}>
      <RowItem container spacing={0} alignItems="center">
        <Grid item xs={2}>
          <Item style={classes.header}>User</Item>
        </Grid>
        <Grid item xs={3}>
          <Item style={classes.header}>Email Address</Item>
        </Grid>
        <Grid item xs={2}>
          <Item style={classes.header}>Role/Dept.</Item>
        </Grid>
        <Grid item xs={3}>
          <Item style={classes.header}>Last Login</Item>
        </Grid>
        <Grid item xs={1} style={classes.header}></Grid>
        <Grid item xs={1} style={classes.header}></Grid>
      </RowItem>
    </Box>
  );
};

const getFooter = (handleClickOpenAdd) => {
  return (
    <Box sx={{ flexGrow: 1 }} style={classes.main}>
      <RowItem container spacing={0} alignItems="center">
        <Grid
          item
          align="center"
          xs={1}
          style={classes.edit}
          onClick={() => {
            handleClickOpenAdd({});
          }}
        >
          <AddCircleOutlineIcon style={classes.addIcon} />
        </Grid>
        <Grid item xs={10}>
          <Item
            style={classes.header}
            onClick={() => {
              handleClickOpenAdd({});
            }}
          >
            Add New User
          </Item>
        </Grid>
      </RowItem>
    </Box>
  );
};

/**
 * Admin panel for CRUD operations on users.
 *
 * The loggedInUser is the user who is signed in at the webapp.
 * It initializes the user state variable, and is then passed in to the Header
 * component.
 *
 * See starter code:
 * https://blog.logrocket.com/create-search-bar-react-from-scratch/
 */
export default function Users({ loggedInUser, yards, roles, usersData }) {
  // Admin panel needs inCard to be set, otherwise Header crashes.
  // Add snackbar when data has been saved #31
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const SAVED_USER_DATA = "Saved User Data";
  const ADDED_NEW_USER = "Added New User";

  const handleCloseSnackbar = () => {
    setSnackBarOpen(false);
  };

  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [userInfoToEdit, setUserInfoToEdit] = React.useState({});
  const [userInfoToDelete, setUserInfoToDelete] = React.useState({});
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const [userQuery, setUserQuery] = React.useState("");
  const [user, setUser] = useState(loggedInUser);

  document.getElementById("main").classList.add("main");

  let navigate = useNavigate();

  useEffect(async () => {
    const authToken = await getAuthToken();
    if (!authToken) {
      console.log("Going to login, no authToken at all!");
      navigate("/login");
      return;
    }

    if (authToken && !user?.data?.role) {
      const userProperties = await fetchGetUser();
      setUser(userProperties);
    }
  }, []);

  const handleClickOpenEdit = (userInfo) => {
    //console.log("handleClickOpenEdit editing : " + JSON.stringify(userInfo));
    setUserInfoToEdit(userInfo);
    setOpenEdit(true);
  };

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleClickDelete = (userInfo) => {
    //console.log("Admin.handleClickDelete " + JSON.stringify(userInfo));
    setUserInfoToDelete(userInfo);
    setOpenConfirmDelete(true);
  };

  /**
   * GETs the /users API, which returns information about the signed in user,
   * like yard, role, username.
   *
   * @returns Promise
   */
  const fetchGetUser = async () => {
    const authToken = await getAuthToken();
    if (!authToken) {
      console.error("Admin Not authenticated to fetchGetUser");
      return;
    }
    const url = `${baseUrl}/users`;
    const result = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const userProperties = await result.json();
    //console.log("Admin.fetchGetUser returns " + JSON.stringify(userProperties));
    return userProperties;
  };

  const fetchRegisterUser = async (userInfo) => {
    const blob = {};
    blob["email"] = userInfo["email"];
    blob["password"] = userInfo["password"]; // TODO FIXME.
    const response = await fetch(`${baseUrl}/register`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(blob),
    });
    const data = await response.json();
    //console.log("fetchRegisterUser " + JSON.stringify(data));
    return data;
  };

  /**
   * DELETE a user.
   *
   * @param {*} userInfo
   * @returns
   */
  const fetchDeleteUser = async (userInfo) => {
    console.log("DELETE user with userInfo " + JSON.stringify(userInfo));
    const authToken = await getAuthToken();
    if (!authToken) {
      console.error("Admin Not authenticated to fetchDeleteUser");
      return;
    }
    const response = await fetch(`${baseUrl}/admin/${userInfo.id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  /**
   * When creating a new user, you first call /register, and then
   * POST initial information to /users (not PATCH)
   *
   * @param {*} userInfo
   * @returns
   */
  const fetchPostUser = async (userInfo, validKeys) => {
    console.log("Posting with userInfo " + JSON.stringify(userInfo));
    const authToken = await getAuthToken();
    if (!authToken) {
      console.error("Admin Not authenticated to fetchPostUser");
      return;
    }
    // Build the JSON blob to save:
    const blob = {};
    validKeys.forEach((key) => {
      if (userInfo[key]) {
        blob[key] = userInfo[key];
      }
    });
    console.log("Posting with blob " + JSON.stringify(blob));
    const response = await fetch(`${baseUrl}/admin/${userInfo.id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(blob),
    });
    const data = await response.json();
    return data;
  };

  const fetchPatchUser = async (userInfo, validKeys) => {
    console.log("Patching with userInfo " + JSON.stringify(userInfo));
    const authToken = await getAuthToken();
    if (!authToken) {
      console.error("Admin Not authenticated to fetchPatchUser");
      return;
    }

    // Build the JSON blob to save:
    const blob = {};
    validKeys.forEach((key) => {
      if (userInfo[key]) {
        blob[key] = userInfo[key];
      }
    });
    console.log("Patching with blob " + JSON.stringify(blob));
    const response = await fetch(`${baseUrl}/admin/${userInfo.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(blob),
    });
    console.log("fetchPatchUser status was " + response.status);
    const data = await response.json();
    console.log("fetchPatchUser data was " + JSON.stringify(data));
    return data;
  };

  const clickedSave = async (userInfo) => {
    const DEBUG = false;

    if (!userInfo.id) {
      console.log("Going to register a new user..." + JSON.stringify(userInfo));
      if (DEBUG) {
        setOpenAdd(false);
        setAlertMessage(ADDED_NEW_USER);
        setSnackBarOpen(true);
        return;
      }
      //rosco
      const registerResults = await fetchRegisterUser(userInfo);
      if (registerResults.error) {
        alert(registerResults.error);
        return;
      }
      if (DEBUG)
        console.log(
          "clickedSave registerResults " + JSON.stringify(registerResults)
        );
      userInfo.id = registerResults.uid;
      //const createResults = await fetchPostUser(userInfo);
      //console.log("clickedSave createResults " + JSON.stringify(createResults));

      // Make sure userInfo has valid role property before passing to fetchPostUser
      if (!userInfo.role || userInfo.role === "incomplete") {
        userInfo.role = "user"; 
      }

      // Also ensure username is set
      if (!userInfo.username) {
        userInfo.username = userInfo.email.split('@')[0]; // Simple default username
      }

      const validKeys = ["role", "yard", "username"];
      console.log("POST with  " + JSON.stringify(userInfo));
      const postResult = await fetchPostUser(userInfo, validKeys);
      console.log("postResult " + JSON.stringify(postResult));

      if (DEBUG)
        console.log("clickedSave pushing userInfo " + JSON.stringify(userInfo));
      usersData.data.push(userInfo);
      setOpenAdd(false);
      setAlertMessage(ADDED_NEW_USER);
      setSnackBarOpen(true);
      return;
    }
    if (DEBUG)
      console.log(
        "Admin.clickedSave - User clicked save " + JSON.stringify(userInfo)
      );
    if (DEBUG) {
      setOpenEdit(false);
      setAlertMessage(SAVED_USER_DATA);
      setSnackBarOpen(true);
      return;
    }
    const validKeys = ["role", "yard", "username", "password", "email"];
    console.log("patch with  " + JSON.stringify(userInfo));

    const data = await fetchPatchUser(userInfo, validKeys);

    if (data.data) {
      console.log("Result of fetch " + JSON.stringify(data));
      const item = usersData.data.find((el) => el.id === userInfo.id);
      validKeys.forEach((key) => {
        if (userInfo[key]) {
          console.log(
            "Updating found user property  " + key + " with " + item[key]
          );
          item[key] = userInfo[key];
        }
      });
      setSnackBarOpen(true);
      setAlertMessage(SAVED_USER_DATA);
      setOpenEdit(false);
    } else {
      console.error(JSON.stringify(data));
      alert("Sorry, there was a problem saving your data");
    }
  };

  const clickedDelete = async (userInfo) => {
    if (!userInfo.id) {
      alert("User does not have a valid id and cannot be deleted");
      return;
    }
    console.log(
      "Admin.clickedDelete - User clicked delete " + JSON.stringify(userInfo)
    );

    const data = await fetchDeleteUser(userInfo);

    if (data.data) {
      console.log("Result of fetch " + JSON.stringify(data));
      const index = usersData.data.findIndex((el) => el.id === userInfo.id);
      usersData.data.splice(index, 1);
      setOpenConfirmDelete(false);
    } else {
      if (data.error) {
        alert("Sorry, there was a problem saving your data. " + data.error);
      } else {
        alert("Sorry, there was a problem saving your data");
      }
    }
  };

  const clickedCancel = () => {
    console.log("User clicked cancel");
    setOpenEdit(false);
    setOpenAdd(false);
    setOpenConfirmDelete(false);
  };

  /**
   * handleSearch is called as a user types into the "search" input.
   * It is implied that the search is on user name.
   *
   * @param {*} username a string like "Joh" or "Doe"
   */
  const handleSearch = (e) => {
    console.log("Admin.handleSearch search for username " + e.target.value);
    setUserQuery(e.target.value || "");
  };

  if (!user?.data) {
    console.warn("There is no user data?? " + JSON.stringify(user));
    if (user && user.error) {
      console.log("Going to navigate to login");
      return <Navigate to="/login" replace />;
    } else {
      // Do not navigate to login, in this case. We may be waiting for a
      // server response to determine if user is authorized. To test this,
      // reload /admin page. TODO FIXME. Show a progressloader.
      return <div className="container">Loading... please wait</div>;
    }
  }

  return (
    <>
      <p></p>
      <SearchInput handleSearch={handleSearch} />
      <p></p>
      {getHeaders()}
      {"" == userQuery
        ? getAllData(usersData.data, handleClickOpenEdit, handleClickDelete)
        : getSomeData(
            userQuery,
            usersData.data,
            handleClickOpenEdit,
            handleClickDelete
          )}
      {getFooter(handleClickOpenAdd)}
      {/* EditDialog is used both to ADD a user and to EDIT a user */}
      <EditDialog
        title={"Edit User"}
        addUser={false} // Dialog is used to edit user, not add one
        clickedSave={clickedSave}
        clickedCancel={clickedCancel}
        open={openEdit}
        userInfo={userInfoToEdit}
        yards={yards.data}
        roles={roles.data}
        isDisabled={true}
      />
      <EditDialog
        title={"Add New User"}
        addUser={true} // Dialog is used to add user
        clickedSave={clickedSave}
        clickedCancel={clickedCancel}
        open={openAdd}
        yards={yards.data}
        roles={roles.data}
        isDisabled={true}
      />
      <DeleteConfirmDialog
        clickedDelete={clickedDelete}
        clickedCancel={clickedCancel}
        open={openConfirmDelete}
        userInfo={userInfoToDelete}
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={alertMessage}
        ContentProps={{ style: { background: "#3ae82a" } }}
      ></Snackbar>
    </>
  );
}

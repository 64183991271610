import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import "./App.css";
import Form from "./components/common/Form";
import Home from "./components/Home";
import ForgotPassword from "./components/ForgotPassword";
import Admin from "./components/admin/Admin";
import Cart from "./components/Cart";
import { baseUrl } from "./_constants";
import { setRefreshToken, getAuthToken, setAuthToken } from "./utils";

import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { blue, green } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { FavoritesProvider } from "./contexts/FavoritesContext";
import env, { REACT_APP_WEB_BASE_DOMAIN_PRODUCTION } from "./env";
import { el } from "date-fns/locale";

const unAuthRoutes = [
  "/forgot-password",
  "/login",
  "/register",
  "/forgot-password/",
  "/login/",
  "/register/",
  "/.well-known/",
];

const theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
      contrastText: green[500],
    },
  },
});

function App() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(""); // must set role when registering
  const [user, setUser] = useState({});
  const [roles, setRoles] = useState([]); // for registration form
  const { pathname } = useLocation(); // determine where to send user
  let navigate = useNavigate();

  const goLogin = () => {
    navigate("/login");
  };

  /**
   * Calls fetchRegisterUser to /register, then signs user in,
   * then POSTs any user details like username, yard, role.
   *
   * @returns Promise
   */
  const handleRegisterUser = async () => {
    const userInfo = { email, password };
    const registerResults = await fetchRegisterUser(userInfo);
    if (registerResults.error) {
      /*alert(registerResults.error);*/
      alert("The email address is already in use or is not supported.");
      return;
    }
    userInfo.id = registerResults.uid;
    userInfo.role = role;

    const validKeys = ["role", "yard", "username"];
    const loginResult = await handleUserLogin();
    const postResult = await fetchPostUser(userInfo, validKeys);
    return userInfo;
  };

  /**
   * GETs the /users API, which returns information about the signed in user,
   * like yard, role, username.
   *
   * @returns Promise
   */
  const fetchGetUser = async () => {
    const url = `${baseUrl}/users`;
    const authToken = await getAuthToken();
    if (!authToken && !unAuthRoutes.includes(pathname)) {
      goLogin();
      return;
    }else{
      if(pathname == '/'){
        navigate("/home");
      }
    }
    const result = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    const userProperties = await result.json();
    return userProperties;
  };

  /**
   * Register a user by POSTing to /register.
   *
   * @param {*} userInfo contains email and password
   * @returns Promise
   */
  const fetchRegisterUser = async (userInfo) => {
    const blob = {};
    blob["email"] = userInfo["email"];
    blob["password"] = userInfo["password"];
    const response = await fetch(`${baseUrl}/register`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(blob),
    });
    const data = await response.json();
    return data;
  };

  /**
   * When creating a new user, you first call /register, and then
   * POST initial information to /users. Swiped this code from Admin.
   * TODO FIXME refactor and make modular.
   *
   * @param {*} userInfo
   * @returns
   */
  const fetchPostUser = async (userInfo, validKeys) => {
    // Build the JSON blob to save:
    const blob = {};
    validKeys.forEach((key) => {
      if (userInfo[key]) {
        blob[key] = userInfo[key];
      }
    });
    const authToken = await getAuthToken();
    if (!authToken && !unAuthRoutes.includes(pathname)) {
      goLogin();
      return;
    }
    const response = await fetch(`${baseUrl}/users`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(blob),
    });
    const data = await response.json();
    return data;
  };

  /**
   * Uses googleapis to sign the user in, and then GETs /users API
   * to decorate the resulting user with properties like role and yard.
   * Resulting user object has property role that is needed to determine
   * permissions for Admin screen.
   *
   * @returns Promise
   */
  const handleUserLogin = async () => {
    const apiKey = env.REACT_APP_FIREBASE_API_KEY; // stage
    const url =
      "https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=" +
      apiKey;
    try {
      const result = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          password: password,
          returnSecureToken: true,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (401 == result.status) {
        alert("Authentication failed, please try again!");
        return;
      }
      const parsedResult = await result.json();
      if (!parsedResult?.idToken) {
        alert("An error occured, please check your data!");
      } else {
        await setAuthToken(parsedResult.idToken);
        await setRefreshToken(parsedResult);
      }
      const userProperties = await fetchGetUser();
      const blob = { ...userProperties, ...parsedResult };
      setUser(blob);
      // store parsedResult in local storage
      localStorage.setItem("current-user", JSON.stringify(blob));
    } catch (err) {
      console.error(err);
      alert("An error occurred signing in. Please try again.");
    }
  };

  const handleAction = async (id) => {
    if (id === 1) {
      await handleUserLogin();
      const urlParams = new URLSearchParams(window.location.search);
      const redirect = urlParams.get("redirect");
      if (redirect) {
        navigate(redirect);
        return;
      } else {
        navigate("/home");
      }

      return;
    }

    if (id === 2) {
      const response = await handleRegisterUser({ email, password });
      if (response.error) {
        alert(response.error);
        return;
      }
      await handleUserLogin();
      navigate("/home");
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      await handleAction(1); // trigger handlerAction
    }
  };

  useEffect(async () => {
    let authToken = await getAuthToken();

    if (!authToken && !unAuthRoutes.includes(pathname)) {
      if (pathname == "/register") {
        navigate("/register");
      } else {
        const redirectPath = `${location.pathname}${location.search}`;

        if (redirectPath.length > 1) {
          navigate(`/login?redirect=${redirectPath}`);
        } else {
          navigate("/login");
        }
      }
      return;
    }
    if (authToken && !user?.data?.role) {
      const userProperties = await fetchGetUser();
      setUser(userProperties);
    } 
  }, []);

  /**
   * Gets roles and sets them in state, needed to populate register dropdown.
   */
  useEffect(async () => {
    const fetchRoles = async () => {
      const result = await fetch(`${baseUrl}/roles/`);
      const response = await result.json();
      const noAdmin = response.data.filter((role) => role.name != "admin");
      return noAdmin;
    };
    const roles = await fetchRoles();
    setRoles(roles);
  }, []);

  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FavoritesProvider user={user}>
          <ToastContainer />
          <Routes>
            <Route
              path="/login"
              element={
                <Form
                  title="Login"
                  setEmail={setEmail}
                  setPassword={setPassword}
                  handleAction={async () => {
                    await handleAction(1);
                  }}
                  handleKeyDown={handleKeyDown}
                />
              }
            />
            <Route
              path="/register"
              element={
                <Form
                  title="Register"
                  setEmail={setEmail}
                  setPassword={setPassword}
                  setRole={setRole}
                  roles={roles}
                  handleAction={() => handleAction(2)}
                />
              }
            />

            <Route path="/forgot-password" element={<ForgotPassword />} />

            <Route path="/home" element={<Home user={user} />} />
            <Route path="/admin" element={<Admin user={user} />} />
            <Route path="/cart" element={<Cart user={user} allPlants="" />} />
          </Routes>
        </FavoritesProvider>
      </LocalizationProvider>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { ImageList } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ImageCard from "../ImageCard";
import ImagesFilters from "../ImagesFilters";
import Heading from "./Heading";
import { filterPlantsPhotos } from "../../helpers";
import { useGetData } from "../../hooks";

export default function ImageGrid({ user, plantPhotos, refetchPlantPhotos }) {
  let totalRows = 1980; // 1980 must came from the api
  let totalPagesCal = Math.ceil(totalRows / 9);
  //const { data: plants } = useGetData("plants");
  // let totalRows  = plants.length;
  // let totalPagesCal = Math.ceil(totalRows / 9);
  const [totalPages, setTotalPages] = useState(totalPagesCal); // Initialize totalPages as a state variable
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [filteredPlantPhotos, setFilteredPlantPhotos] = useState(plantPhotos);

  const resetFilters = () => setFilters({ ...INITIAL_FILTERS });

  useEffect(() => {
    const filteredPhotos = filterPlantsPhotos(plantPhotos, filters);
    setFilteredPlantPhotos(filteredPhotos);
    if (filters.startDate !== null || filters.endDate !== null || filters.yard !== '') {
      setTotalPages(Math.ceil(filteredPhotos.length / 9)); // Update totalPages using setTotalPages
    }
  }, [plantPhotos, filters]);

  // useEffect(() => {
  //   let search = filters.plantId;

  //   if (filters.plantId !== INITIAL_FILTERS.plantId) {
  //     search += "/*/?page=1";
  //   }

  //   refetchPlantPhotos(search);
  // }, [filters.plantId]);

  useEffect(() => {
    let search = filters.plantId;

    if (filters.plantId !== INITIAL_FILTERS.plantId) {
      search += "/*/?page=1";
    }

    refetchPlantPhotos(search);
  }, [filters.plantId, refetchPlantPhotos, INITIAL_FILTERS.plantId]);

  const handleChange = (event, value) => {
    let search = filters.plantId;

    search += "?page=" + value;

    refetchPlantPhotos(search);
  };

  const onNewPlantCreate = () => { };

  return (
    <>
      <ImagesFilters
        resetFilters={resetFilters}
        filters={filters}
        setFilters={setFilters}
      />

      <Heading
        INITIAL_FILTERS={INITIAL_FILTERS}
        filters={filters}
        plantPhotos={filteredPlantPhotos}
      />

      <ImageList variant="masonry" cols={3} gap={8}>
        {filteredPlantPhotos.map((plantPhoto) => (
          <ImageCard
            key={`plant-photo-${plantPhoto.id}`}
            user={user}
            data={plantPhoto}
            id={'image-photo-' + plantPhoto.id}
          />
        ))}
      </ImageList>

      <Stack spacing={2} display="flex" justifyContent="center">
        <Pagination
          style={{ justifyContent: "center", display: "flex", marginTop: 40 }}
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </Stack>
    </>
  );
}

const INITIAL_FILTERS = {
  startDate: null,
  endDate: null,
  plantId: "%2A/%2A",
  yard: "",
  sort: "newest",
  label: "Most Recent Photos",
  hideBeforeDecember2023: true,
};
